import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';

export function useLocale() {
  const { locale = i18n.defaultLocale } = useRouter();
  return { locale, ...(locale.match(/(?<lang>.+)-(?<country>.+)/)?.groups as { lang: string; country: string }) };
}

export function useCustomLocale(languageCode: string): { locale: string; country: string; lang: string };
export function useCustomLocale(languageCode?: string): Partial<{ locale: string; country: string; lang: string }> {
  return languageCode
    ? {
        locale: languageCode,
        ...(languageCode.match(/(?<lang>.+)-(?<country>.+)/)?.groups as { lang: string; country: string }),
      }
    : {};
}
