import { faBars } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { FC, MouseEventHandler } from 'react';

type Props = {
  className?: string;
  onClick: MouseEventHandler;
};

export const MobileMenuButton: FC<Props> = ({ onClick, className }) => {
  const { t } = useTranslation('common');
  return (
    <button
      type="button"
      aria-label={t('common:navigation.toggleMainMenu')}
      onClick={onClick}
      className={className}
      data-testid="mobile-menu-button"
    >
      <FontAwesomeIcon
        className="menu-icon"
        icon={faBars}
        title={t('common:navigation.toggleMainMenu')}
        titleId="toggleMainMenuIcon"
      />
    </button>
  );
};
