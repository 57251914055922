import cn from 'classnames';
import { forwardRef, useImperativeHandle, useRef } from 'react';

import { Text } from '@components/ui';
import extractColorHex from '@lib/extract-color-hex';

import { MessageBarHeightHandle } from '../types/ModuleMessageBar';

import styles from './MessageBar.module.scss';

interface Props {
  content?: string;
  backgroundColor?: string;
  textColor?: string;
}

const MessageBar = forwardRef<MessageBarHeightHandle, Props>(({ content, backgroundColor, textColor }, ref) => {
  const textRef = useRef<HTMLDivElement>(null);

  const bgColor = (backgroundColor && extractColorHex(backgroundColor)) ?? 'var(--mint)';
  const color = (textColor && extractColorHex(textColor)) ?? 'var(--eggplant)';

  useImperativeHandle(
    ref,
    () => {
      return {
        heightMeasure() {
          return textRef.current?.offsetHeight;
        },
      };
    },
    []
  );

  return (
    <div className="message-bar" style={{ backgroundColor: bgColor }}>
      {content && (
        <Text
          className={cn(styles.root)}
          color={color}
          variant="medium"
          asElement="div"
          data-testid="message-bar-wrapper"
          html={content}
          ref={textRef}
        />
      )}
    </div>
  );
});

MessageBar.displayName = 'MessageBar';

export default MessageBar;
