import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import type { LineItem } from '@commerce/types/cart';
import { Text } from '@components/ui';
import Button from '@components/ui/Button';
import { useUI } from '@components/ui/context';
import useCart from '@framework/cart/use-cart';
import { LAST_ADDED_SKU_LOCAL_STORAGE } from '@framework/const';
import usePrice from '@framework/product/use-price';
import ClickOutside from '@lib/click-outside';

import CartHeader from '../CartHeader';
import CartItem from '../CartItem';

import style from './CartOverlay.module.scss';

// can't check lastItemAdded from BigC cart items array only, as adding an item that already exists in the cart (update quantity) may not be returned as the last array item
// hence using localStorage to store the last added sku
const getLastItemAdded = (locale: string, lineItems?: LineItem[]): LineItem | null => {
  if (!lineItems || !lineItems.length) {
    return null;
  }
  const lastAddedSlug = window?.localStorage?.getItem?.(`${LAST_ADDED_SKU_LOCAL_STORAGE}_${locale}`);
  if (!lastAddedSlug) {
    return lineItems[lineItems.length - 1];
  }
  return lineItems.find(({ variant: { sku } }) => sku === lastAddedSlug) ?? null;
};

interface Props {
  hideOverlayArrow?: boolean;
}

const CartOverlay: FC<Props> = ({ hideOverlayArrow = false }) => {
  const { t } = useTranslation(['common', 'cart']);
  const { locale } = useRouter();
  const { closeCartOverlay, displayCartOverlay } = useUI();
  const { data } = useCart();

  const lastItemAdded = getLastItemAdded(locale!, data?.lineItems);
  const itemsCount = data?.lineItems.reduce((count: number, item: LineItem) => count + item.quantity, 0) ?? 0;

  const { price: subtotal } = usePrice(
    data && {
      amount: Number(data.subtotalPrice),
      currencyCode: data.currency.code,
    }
  );

  return (
    <ClickOutside active={displayCartOverlay} onClick={() => closeCartOverlay()}>
      <div className={cn(style.root, { [style.noArrow]: hideOverlayArrow })} data-cy="cart-overlay">
        <div className={style.wrapper}>
          <button
            data-testid="cart-overlay-close-btn"
            aria-label="Close"
            type="button"
            onClick={() => closeCartOverlay()}
            className={style.close}
          >
            <FontAwesomeIcon
              className="text-secondary"
              icon={faTimes}
              size="lg"
              title={t('cart:action.closeCartPanel')}
            />
          </button>
          <CartHeader
            itemCount={itemsCount}
            subtotal={subtotal}
            showSubtotal={!!data?.subtotalPrice}
            className="mb-xs flex flex-row gap-x-3"
            headerClassName={style.header}
          />
          <Text variant="heading-5" className="mb-xs">
            {t('cart:header.lastItemAdded')}
          </Text>
          {lastItemAdded && (
            <CartItem showAction={false} item={lastItemAdded} currencyCode={data!.currency.code} smallContainer />
          )}
          <div className="flex justify-around mt-l gap-x-s">
            <Button
              aria-label={t('cart:action.viewCart')}
              type="button"
              href="/cart"
              onClick={() => closeCartOverlay()}
              data-cy="go-to-cart"
              className={style.button}
            >
              <Text variant="heading-5">{t('cart:action.viewCart')}</Text>
            </Button>
            <Button
              aria-label={t('cart:action.checkout')}
              variant="cta"
              type="button"
              href="/checkout"
              onClick={() => closeCartOverlay()}
              data-cy="go-to-checkout"
              className={style.button}
              prefetch={false}
            >
              <Text variant="heading-5">{t('cart:action.checkout')}</Text>
            </Button>
          </div>
        </div>
      </div>
    </ClickOutside>
  );
};

export default CartOverlay;
