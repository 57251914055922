export enum ModalViewsEnum {
  'SWITCH_REGION' = 'SWITCH_REGION',
  'SIGNUP_VIEW' = 'SIGNUP_VIEW',
  'PASSWORD_LOGIN_VIEW' = 'PASSWORD_LOGIN_VIEW',
  'LINK_LOGIN_VIEW' = 'LINK_LOGIN_VIEW',
  'LINK_LOGIN_SENT_VIEW' = 'LINK_LOGIN_SENT_VIEW',
  'RESEND_VIEW' = 'RESEND_VIEW',
  'NEW_SHIPPING_ADDRESS' = 'NEW_SHIPPING_ADDRESS',
  'NEW_PAYMENT_METHOD' = 'NEW_PAYMENT_METHOD',
  'CHANGE_EMAIL_VERIFICATION' = 'CHANGE_EMAIL_VERIFICATION',
  'LOADING_VIEW' = 'LOADING_VIEW',
  'LOGOUT_CONFIRM_VIEW' = 'LOGOUT_CONFIRM_VIEW',
}

export type ModalViews = keyof typeof ModalViewsEnum;

export interface Modal {
  view: ModalViews;
  data?: Record<string, any>;
  className?: string;
  hideCloseBtn?: boolean;
}

export const isModalOpenableViaQueryParams = (key?: string | null): key is ModalViewsEnum => {
  if (key && key in ModalViewsEnum) {
    const modalsOpenableViaQueryParams = [ModalViewsEnum.PASSWORD_LOGIN_VIEW];
    return modalsOpenableViaQueryParams.includes(key as ModalViewsEnum);
  }
  return false;
};
