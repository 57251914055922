import { type ReactNode } from 'react';

import { type Product } from '@commerce/types/product';
import { ProductAttributeField } from '@components/product/enums';
import { getProductCustomField } from '@components/product/helpers';

type Props = {
  product: Product | null;
  isDownloadPage?: boolean;
  children: ReactNode;
};

export const LayoutMessageBarVisibilityManager = ({ children, product, isDownloadPage }: Props) => {
  if (isDownloadPage) {
    return null;
  }

  const shouldHidePromoBar = getProductCustomField(product, ProductAttributeField.HIDE_PROMOTION_MESSAGE_BAR) === 'Yes';
  if (shouldHidePromoBar) {
    return null;
  }

  return children;
};
