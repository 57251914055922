import { TFunction } from 'next-i18next';

const commonErrorBase = 'common:error.';

export const getI18nError = (t: TFunction, errorCode: string, base = commonErrorBase): string => {
  // check from base i18n namespace
  // fallback to common namespace if not found
  // last fallback to common default error
  return t(`${base}${errorCode}`) || t(`${commonErrorBase}${errorCode}`) || t(`${commonErrorBase}default`);
};
