import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { FC, useState } from 'react';

import I18nDialogSelector from '@components/common/I18nSelector/I18nDialogSelector';

import { LOCALES_MAP } from './constants';

import s from './I18nSelector.module.scss';

type Props = {
  onClick?: () => void;
};

const I18nSelector: FC<Props> = ({ onClick }) => {
  const [display, setDisplay] = useState(false);
  const { locale, defaultLocale = i18n.defaultLocale } = useRouter();

  const { shortName, flag } = LOCALES_MAP[locale || defaultLocale];
  const { t } = useTranslation(['common']);

  return (
    <div className={s.root}>
      <div className="flex items-center relative">
        <button
          type="button"
          data-cy="locale-switcher"
          className={cn(s.text, s.mobileSecondaryLink)}
          onClick={() => setDisplay(!display)}
        >
          <span aria-hidden className="inline text-xl leading-tight">
            {flag}
          </span>
          <span aria-hidden className="lg:hidden">
            {shortName}
          </span>

          <span aria-hidden className="hidden lg:inline">
            {t('common:languageSelector.store', { country: shortName })}
          </span>
          <span className="sr-only">{t('languageSelector.chooseYourCountry')}</span>
        </button>
      </div>
      {display && (
        <I18nDialogSelector
          onClick={() => {
            setDisplay(false);
            onClick?.();
          }}
          onClose={() => {
            setDisplay(false);
          }}
        />
      )}
    </div>
  );
};

export default I18nSelector;
