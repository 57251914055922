import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { Button, Container, Text } from '@components/ui';
import { GlobalQueryString } from '@components/ui/context';
import { getStorage, setStorage } from '@lib/browser-storage';
import { useCustomLocale, useLocale } from '@lib/hooks/useLocale';

import s from './I18nDetectorDialog.module.scss';

const LOCALE_POPUP = 'LOCALE_POPUP_SHOWN';

interface Props {
  userLocale: string;
}

const I18nDetectorDialog = ({ userLocale }: Props) => {
  const { t } = useTranslation(['common', 'countries']);
  const { push, pathname, query } = useRouter();
  const { country: currentCountryCode, locale } = useLocale();
  const { country: userCountry } = useCustomLocale(userLocale);
  const [showPopup, setShowPopup] = useState(() => !!userLocale);
  const isShown = getStorage(`${LOCALE_POPUP}_${locale}`);

  const onClose = () => {
    setShowPopup(false);
    setStorage(`${LOCALE_POPUP}_${locale}`, 'yes');
  };

  const onSubmit = async () => {
    onClose();
    await push({ pathname, query: omit(query, GlobalQueryString.USER_LOCALE) }, '', { locale: userLocale });
  };

  if (!showPopup || isShown === 'yes') {
    return null;
  }

  return (
    <Container className={s.root}>
      <div className={s.background} />
      <div className={s.container}>
        <div className={s.popup}>
          <div className={s.header}>
            <Text variant="heading-3" color="var(--white)" asElement="h2">
              {t('common:languageDetector.title')}
            </Text>
            <button onClick={onClose} aria-label={t('common:close')} type="button">
              <FontAwesomeIcon icon={faCircleXmark} color="var(--white)" />
            </button>
          </div>
          <div className={s.body}>
            <Text variant="text-2">
              {t('common:languageDetector.description', {
                country: t(`countries:countries.${userCountry}`),
                countryAdjective: t(`countries:countriesAdjective.${userCountry}`),
              })}
            </Text>
          </div>
          <div className={s.actions}>
            <Button variant="cta" type="button" onClick={onSubmit}>
              {t('common:languageDetector.changeBtn', { country: t(`countries:countries.${userCountry}`) })}
            </Button>
            <Button type="button" onClick={onClose}>
              {t('common:languageDetector.continueBtn', { country: t(`countries:countries.${currentCountryCode}`) })}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default I18nDetectorDialog;
