/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

import { Link } from '@components/ui';

import { ClickableProps, NavigationSubmenu } from '../types';

export const Submenu: FC<ClickableProps<NavigationSubmenu>> = ({ title, links, onClick }) => (
  <nav className="flex flex-col">
    <p className="text-left uppercase font-bold text-purple text-base mb-[10px] leading-4 cursor-default">{title}</p>
    <ul className="space-y-0.5">
      {links.map((l, i) => (
        <li key={`${i}${l.id}`} className="text-left">
          <Link
            href={l.content.url}
            className="text-black text-base font-normal leading-[22.4px]"
            prefetch={false}
            onClick={onClick}
          >
            {l.content.title}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);
