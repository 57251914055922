import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  ChangeEvent,
  FC,
  FocusEventHandler,
  KeyboardEvent,
  MouseEvent,
  MouseEventHandler,
  RefObject,
  useEffect,
  useState,
} from 'react';

import style from './SearchInput.module.scss';

interface Props {
  id?: string;
  value: string;
  placeholder: string;
  onSearch: () => void;
  onChange: (val: string) => void;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  onClear: MouseEventHandler;
  clearOnMouseDown?: boolean;
  className?: string;
  btnClassName?: string;
  inputRef: RefObject<HTMLInputElement>;
}

const SearchInput: FC<Props> = ({
  id,
  value: initialValue,
  placeholder,
  onSearch,
  onChange,
  onFocus,
  onBlur,
  onClear: handleClear,
  clearOnMouseDown = false,
  className,
  btnClassName,
  inputRef,
}) => {
  const [value, setValue] = useState(initialValue);
  const router = useRouter();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const updated = e.currentTarget.value;
    setValue(updated);
    if (onChange) {
      onChange(updated);
    }
  };

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') {
      return;
    }

    onSearch();
  };

  const onClear = (e: MouseEvent) => {
    setValue('');
    handleClear(e);
  };

  // for desktop view, mouseDown event is needed so that it's triggered before blur
  const clearHandler = clearOnMouseDown
    ? {
        onMouseDown: onClear,
      }
    : { onClick: onClear };

  const inputId = id ?? 'search-input';
  const { t } = useTranslation(['product']);

  useEffect(() => {
    if (!router?.pathname?.toLowerCase().includes('/products')) {
      setValue('');
    }
  }, [router?.pathname]);

  return (
    <>
      <input
        name={inputId}
        type="search"
        title={t('product:search.placeholder.normal')}
        aria-label={t('product:search.placeholder.normal')}
        data-testid="search-input"
        data-cy="search-input"
        className={cn(style.input, { [style.notEmpty]: !!value }, className)}
        ref={inputRef}
        autoComplete="off"
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleOnChange}
        onKeyPress={onKeyPress}
        value={value}
      />
      {value && (
        <button data-testid="clear-button" type="button" {...clearHandler} className={cn(style.clear, btnClassName)}>
          <FontAwesomeIcon className="menu-icon" icon={faTimes} title={t('product:search.action.clearSearchInput')} />
        </button>
      )}
    </>
  );
};

export default SearchInput;
