import { faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';

import { useUI } from '@components/ui';
import { useCustomer } from '@framework/customer';

import s from './button.module.scss';

export const AuthButton = ({ onClick }: { onClick?: MouseEventHandler }) => {
  const accountPath = '/account';
  const { data: customer } = useCustomer();
  const isAuthenticated = !!customer;
  const router = useRouter();
  const { t } = useTranslation(['common', 'auth']);
  const isDisabled = router.asPath.startsWith(accountPath);
  const ui = useUI();

  if (isAuthenticated) {
    return (
      <Link
        data-cy="avatar-authenticated"
        data-testid="my-account-button"
        href={accountPath}
        className={cn(s.text, 'flex flex-row items-center space-x-2', {
          'pointer-events-none': isDisabled,
        })}
        onClick={onClick}
      >
        <span>{t('auth:action.account')}</span>

        <FontAwesomeIcon icon={faUserCircle} title="user icon" aria-hidden />
      </Link>
    );
  }

  return (
    <button
      type="button"
      data-cy="avatar"
      onClick={(e) => {
        ui.openModal();
        if (onClick) {
          onClick(e);
        }
      }}
      disabled={isDisabled}
      className="flex flex-row items-center space-x-2 lg:flex-row lg:space-x-2"
    >
      <span className={cn('hidden lg:inline', s.text)}>{t('auth:action.login')}</span>
      <span className={cn('lg:hidden', s.text)}>{t('auth:action.loginStart')}</span>

      <FontAwesomeIcon icon={faUserCircle} title="user icon" aria-hidden />
    </button>
  );
};
