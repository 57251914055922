import { FC } from 'react';

import { Container, useUI } from '@components/ui';

import { CartButton } from './buttons';
import { DesktopNavigation } from './desktop/DesktopNavigation';
import { MobileNavigation } from './mobile/MobileNavigation';
import { MobileDropDownTransitiveProps, NavigationProps } from './types';

type Props = Partial<NavigationProps> & MobileDropDownTransitiveProps;

export const Navigation: FC<Props> = ({ linksOrMenus, calcDropDownTop }) => {
  const { globalParams } = useUI();
  const inApp = globalParams.inapp === 1;

  if (!linksOrMenus) {
    return null;
  }

  const filteredLinksOrMenus = linksOrMenus.filter((item) => item.contentType);

  if (inApp) {
    return (
      <Container mobilePadding asElement="header" className="bg-white py-4 flex flex-row justify-end">
        <CartButton />
      </Container>
    );
  }

  return (
    <>
      <MobileNavigation linksOrMenus={filteredLinksOrMenus} calcDropDownTop={calcDropDownTop} />
      <DesktopNavigation linksOrMenus={filteredLinksOrMenus} />
    </>
  );
};
