import { NavigationItem, NavigationProps } from './types';

export type Node = { type: 'node' } & Pick<NavigationItem, 'title' | 'url' | 'theme'>;

export type Section = {
  title: string;
  nodes: Node[];
  type: 'section';
  defaultOpen: boolean;
};

export type UrlList = Array<Node | Section>;

export const createUrlList = (linksOrMenus: NavigationProps['linksOrMenus']) => {
  return linksOrMenus.reduce((acc, curr) => {
    if (curr.contentType === 'navigationItem') {
      if (curr.content.url && curr.content.title) {
        acc.push({
          type: 'node',
          url: curr.content.url,
          title: curr.content.title,
          theme: curr.content.theme,
        });
      }
    } else {
      const linkNodes: Node[] = curr.content.links.map(({ content: { url, title } }) => ({ url, title, type: 'node' }));
      const subMenuNodes: Node[] = (curr.content.submenus ?? [])
        .map(({ content: { links } }) => links)
        .flat()
        .map(({ content: { title, url } }) => ({ title, url, type: 'node' }));
      const standaloneNodes: Node[] = curr.content.standaloneLink
        ? [
            {
              title: curr.content.standaloneLink.content.title,
              url: curr.content.standaloneLink.content.url,
              type: 'node',
            },
          ]
        : [];
      const nodes = linkNodes
        .concat(...subMenuNodes)
        .concat(...standaloneNodes)
        .filter((node) => node && node.url && node.title);

      if (nodes.length) {
        acc.push({
          type: 'section',
          title: curr.content.title,
          nodes,
          defaultOpen: curr.content.defaultMobileState,
        });
      }
    }
    return acc;
  }, [] as UrlList);
};
