import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FC, MouseEventHandler, useState } from 'react';

import { I18nSelector } from '@components/common';
import { Link } from '@components/ui';
import randomId from '@lib/random-id';

import { AuthButton, HelpButton } from '../buttons';
import { Section, UrlList } from '../createUrlList';

import s from './Dropdown.module.scss';

const DropdownSection: FC<Section & { onClick: MouseEventHandler }> = ({ title, nodes, defaultOpen, onClick }) => {
  const [open, setOpen] = useState(defaultOpen);
  const id = `dropdownSection-${randomId()}`;

  return (
    <div key={title} className={s.section}>
      <button
        type="button"
        className={s.link}
        onClick={() => setOpen((v) => !v)}
        aria-expanded={open ? 'true' : 'false'}
        aria-controls={id}
      >
        <div className={s.iconContainer}>
          <span className={s.sectionHeader}>{title}</span>
          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} aria-hidden />
        </div>
      </button>

      <ul id={id} className={cn({ hidden: !open })} data-testid={id}>
        {nodes.map((node) => (
          <li key={node.title} className={s.subLinkContainer}>
            <Link href={node.url} className={s.subLink} onClick={onClick}>
              <div className={s.iconContainer}>
                {node.title}
                <FontAwesomeIcon icon={faChevronRight} aria-hidden />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const Dropdown: FC<{ list: UrlList; close: () => void; top?: number }> = ({ list, close, top }) => {
  const topPos = top ? top + 56 : 56; // $navigation-bar-height: 56px;
  return (
    <section className={s.dropdown} style={{ top: `${topPos}px` }}>
      <div className={s.dropdownHeader}>
        <AuthButton onClick={close} />
      </div>
      <div className={s.dropdownScrollableArea}>
        <div className={s.dropdownMainLinks}>
          {list.map((item) =>
            item.type === 'node' ? (
              <Link
                key={item.title}
                href={item.url}
                className={cn(s.link, {
                  [s.sale]: item.theme === 'Sale',
                })}
                onClick={close}
                prefetch={false}
              >
                {item.title}
              </Link>
            ) : (
              <DropdownSection key={item.title} onClick={close} {...item} />
            )
          )}
        </div>
        <div className={s.dropdownExtraLinks}>
          <div className="p-4">
            <HelpButton onClick={close} />
          </div>
          <div className="p-4">
            <I18nSelector onClick={close} />
          </div>
        </div>
      </div>
    </section>
  );
};
