/* eslint-disable react/no-array-index-key */
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FC, FocusEventHandler, MouseEventHandler, useState } from 'react';

import { I18nSelector, Searchbar } from '@components/common';
import { Container, Link } from '@components/ui';

import { AuthButton, CartButton, HelpButton, LogoButton } from '../buttons';
import { ClickableProps, NavigationMenu, NavigationProps } from '../types';
import { MenuCard } from './MenuCard';
import { StandaloneLink } from './StandaloneLink';
import { Submenu } from './Submenu';

import s from './DesktopNavigation.module.scss';

const Menu: FC<ClickableProps<NavigationMenu>> = ({ links = [], submenus = [], standaloneLink, onClick }) => {
  return (
    <div className={s.menu}>
      <Container>
        <div
          className={cn({
            [s.containerNotFull]: links.length < 4,
          })}
        >
          <nav className="border-b pb-4 flex flex-row flex-nowrap space-x-8">
            {links.map((link, index) => (
              <MenuCard key={`${index}${link.id}`} {...link.content} onClick={onClick} totalCount={links.length} />
            ))}
          </nav>
          <div className="pt-4 flex flex-row justify-between min-h-[85px]">
            <div className="flex flex-row space-x-10">
              {submenus.map((submenu, index) => (
                <Submenu key={`${index}${submenu.id}`} {...submenu.content} onClick={onClick} />
              ))}
            </div>
            {standaloneLink && <StandaloneLink {...standaloneLink.content} onClick={onClick} />}
          </div>
        </div>
      </Container>
    </div>
  );
};

const MenuButton: FC<
  ClickableProps<NavigationMenu> & {
    activeMenu?: string;
    open: (key: string) => void;
    close: () => void;
  }
> = (props) => {
  const { title, activeMenu, open, close, onClick } = props;
  const isOpen = activeMenu === title;

  const handleOpen = () => {
    open(title);
  };

  const handleMouseEnter: MouseEventHandler = (e) => {
    e.preventDefault();
    handleOpen();
  };

  const handleFocus: FocusEventHandler = (e) => {
    e.preventDefault();
    handleOpen();
  };

  return (
    <div
      className={cn(s.link, {
        [s.active]: isOpen,
      })}
    >
      <button type="button" onMouseLeave={close} className={s.overlayTrigger} onFocus={handleFocus}>
        <div className="flex flex-row items-center">
          <span className="whitespace-nowrap" onMouseEnter={handleMouseEnter} onFocus={handleFocus}>
            {title}
          </span>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="ml-2" aria-hidden />
        </div>
        <Menu {...props} onClick={onClick} />
      </button>
    </div>
  );
};

export const DesktopNavigation: FC<{ linksOrMenus: NavigationProps['linksOrMenus'] }> = ({ linksOrMenus = [] }) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | undefined>();
  const close = () => setActiveMenu(undefined);
  const open = (title: string) => setActiveMenu(title);

  return (
    <header className="hidden lg:block bg-white relative">
      <nav className="border-b-[0.5px] border-[#111111]">
        <Container className="relative flex flex-row justify-end space-x-5 py-[10.5px]">
          <HelpButton />
          <I18nSelector />
          <AuthButton />
        </Container>
      </nav>
      <Container className={cn(s.mainNavigation, { [s.searchFocused]: isSearchFocused })}>
        <LogoButton className={cn(s.logo, 'relative w-32 self-center')} />
        <nav className={s.mainLinks}>
          {linksOrMenus.map((item, index) =>
            item.contentType === 'navigationMenu' ? (
              <MenuButton
                key={`${index}${item.id}`}
                {...item.content}
                activeMenu={activeMenu}
                open={open}
                close={close}
                onClick={close}
              />
            ) : (
              <Link
                key={`${index}${item.id}`}
                href={item.content.url}
                className={cn(s.link, s.linkPadding, {
                  [s[item.content.theme?.toLocaleLowerCase() ?? '']]: !!item.content.theme,
                })}
                onFocus={close}
              >
                {item.content.title}
              </Link>
            )
          )}
        </nav>

        <div className={s.extraLinks}>
          <div className={s.searchBar}>
            <Searchbar onBlur={() => setIsSearchFocused(false)} onFocus={() => setIsSearchFocused(true)} />
          </div>

          <div className={s.cartButton}>
            <CartButton />
          </div>
        </div>
      </Container>
    </header>
  );
};
