import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import InputText, { Props } from '../InputText/InputText';

import style from './PasswordInputText.module.scss';

export interface PasswordProps extends Props {
  registerInput: UseFormRegisterReturn;
}

const PasswordInputText: FC<PasswordProps> = (props) => {
  const { t } = useTranslation(['auth']);
  const [passwordShown, setPasswordShown] = useState(false);
  const btnText = t(`auth:label.${passwordShown ? 'hide' : 'show'}Password`);
  const togglePassword = () => setPasswordShown(!passwordShown);
  const { registerInput, ...otherProps } = props;

  return (
    <InputText
      type={passwordShown ? 'text' : 'password'}
      icon={
        <button aria-label={btnText} title={btnText} className={style.eyeBtn} type="button" onClick={togglePassword}>
          <FontAwesomeIcon icon={passwordShown ? faEye : faEyeSlash} size="sm" />
        </button>
      }
      {...registerInput}
      {...otherProps}
    />
  );
};

PasswordInputText.displayName = 'PasswordInputText';

export default PasswordInputText;
