import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { Link } from '@components/ui';
import { trackNavBarEvent } from '@lib/gtag';
import { renderImage } from '@lib/image';

type Props = { className?: string; onClick?: () => void };

export const LogoButton: FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation('common');
  const logoAlt = t('common:navigation.logoAlt');
  return (
    <Link
      data-testid="logo-home-link"
      href="/"
      title="home"
      onClick={() => {
        trackNavBarEvent('home');
        onClick?.();
      }}
      className={className}
    >
      <span className="sr-only">Homepage</span>
      {renderImage(
        { url: '/svgs/life360-tile-logo-mobile.svg', alt: logoAlt },
        { className: 'lg:hidden', 'aria-hidden': true }
      )}
      {renderImage(
        { url: '/svgs/life360-tile-logo.svg', alt: logoAlt },
        { className: 'hidden lg:block', 'aria-hidden': true }
      )}
    </Link>
  );
};
