import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next.config';
import { FC, KeyboardEvent, MouseEvent, useState } from 'react';
import { useForm } from 'react-hook-form';

import { validateEmailFormatOnly } from '@components/checkout/validations';
import { Button, InputText, Text } from '@components/ui';
import { useUI } from '@components/ui/context';
import { ModalView } from '@components/ui/Modal/Modal';
import { ModalViews } from '@components/ui/modals';
import callApiRoutes from '@lib/api/call-api-routes';
import getErrorCode from '@lib/get-error-code';
import { getI18nError } from '@lib/get-i18n-error';

import style from '../auth.module.scss';

type LoginLinkFormValues = {
  email: string;
};

interface Props extends ModalView {
  email?: string;
}

const LinkLoginView: FC<Props> = ({ email: defaultEmail }) => {
  const { t } = useTranslation(['common', 'auth']);

  const { locale } = useRouter();

  const [loading, setLoading] = useState(false);
  const { setModal } = useUI();

  const defaultValues: LoginLinkFormValues = { email: defaultEmail ?? '' };

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors, isValid, isSubmitted },
    getValues,
  } = useForm<LoginLinkFormValues>({
    defaultValues,
    mode: 'onTouched',
  });

  const validationRules = {
    email: {
      required: t('common:error.empty.email'),
      validate: {
        value: (email: string) => validateEmailFormatOnly(email).success || t('auth:error.invalid_email').toString(),
      },
    },
  };

  const onSubmit = async ({ email }: LoginLinkFormValues) => {
    trigger();
    if (!isSubmitted || isValid) {
      try {
        await callApiRoutes(`/api/auth-code-email?locale=${locale}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            redirectTo: `${window.location.origin}${locale !== i18n.defaultLocale ? `/${locale}` : ''}`,
          }),
        });
        setLoading(false);
        setModal('LINK_LOGIN_SENT_VIEW', { email });
      } catch (err) {
        const errorCode = getErrorCode(err);
        setError('email', { message: getI18nError(t, errorCode, 'auth:error.') }, { shouldFocus: true });
        setLoading(false);
      }
    }
  };

  const handleModalView = (e: MouseEvent | KeyboardEvent, view: ModalViews) => {
    if (e.type === 'click' || (e.type === 'keydown' && (e as KeyboardEvent).key === 'Enter')) {
      setModal(view, !errors.email ? { email: getValues('email') } : undefined);
    }
  };

  return (
    // noValidate to disable default browser form validation
    <form onSubmit={handleSubmit(onSubmit)} className={style.form} noValidate>
      <Text className="mb-xxl text-center" variant="heading-3" weight="semibold">
        {t('auth:header.loginStart')}
      </Text>
      <InputText
        type="email"
        className="mb-xxl"
        label={t('auth:label.email')}
        data-cy="login-email"
        error={errors.email}
        defaultValue={defaultValues.email}
        aria-required={!!validationRules.email.required}
        {...register('email', validationRules.email)}
      />
      <Button
        variant="cta"
        type="submit"
        className={cn(style.button, 'mb-l w-full')}
        loading={loading}
        disabled={isSubmitted && !isValid}
        data-cy="login-link-button"
      >
        <Text variant="heading-5" asElement="span">
          {t('auth:action.loginWithLink')}
        </Text>
      </Button>
      <Button
        type="button"
        className={cn(style.button, 'mb-xxl')}
        data-cy="login-with-password"
        onKeyDown={(e) => handleModalView(e, 'PASSWORD_LOGIN_VIEW')}
        onClick={(e) => handleModalView(e, 'PASSWORD_LOGIN_VIEW')}
      >
        <Text variant="heading-5" asElement="span">
          {t('auth:action.usePassword')}
        </Text>
      </Button>
      <a
        className={style.link}
        role="button"
        data-cy="signup-link"
        tabIndex={0}
        onKeyDown={(e) => handleModalView(e, 'SIGNUP_VIEW')}
        onClick={(e) => handleModalView(e, 'SIGNUP_VIEW')}
      >
        <Text className={style.linkText} variant="text-4" color="var(--cta)" asElement="span">
          {t('auth:action.signUpLink')}
        </Text>
      </a>
    </form>
  );
};

export default LinkLoginView;
