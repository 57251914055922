import type { FC } from 'react';

import { Button } from '@components/ui';

import type { ClickableProps, NavigationItem } from '../types';

import s from './StandaloneLink.module.scss';

export const StandaloneLink: FC<ClickableProps<NavigationItem>> = ({ title, description, url, onClick }) => (
  <div>
    <div className={s.root}>
      {description && <p className={s.description}>{description}</p>}
      <Button variant="cta" href={url} onClick={onClick} className={s.button}>
        {title}
      </Button>
    </div>
  </div>
);
